import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Lozenge from "../../../packages/Lozenge/src";
import Flex from "../../../packages/Flex/src";
import Div from "../../../packages/Div/src";
import RatioContainer from "../../../packages/RatioContainer/src";
import Icon from "../../../packages/Icon/src";
import Autocomplete from "../../../packages/Autocomplete/src";
import { Heading, P } from "../../../packages/Typography/src";

var EssayHeaderPattern = function EssayHeaderPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Div, {
    bg: "grey.900"
  }, React.createElement(Container, {
    size: "xs",
    pt: "10rem"
  }, React.createElement(Flex, {
    justifyContent: "center"
  }, React.createElement(Lozenge, null, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Icon, {
    name: "update",
    size: "1rem",
    mr: "0.25rem"
  }), "Updated last week"))), React.createElement(Heading, {
    as: "h1",
    size: "xxxl",
    textAlign: "center",
    my: "1.5rem",
    color: "white"
  }, "The democracy scale"), React.createElement(P, {
    size: "lg",
    textAlign: "center",
    muted: true,
    color: "white"
  }, "The democracy scale measures the state of democracy in 167 countries, from authoritarian to full democracy."), React.createElement(Autocomplete, {
    size: "lg",
    raised: true
  })), React.createElement(Container, {
    mt: "5rem"
  }, React.createElement(RatioContainer, {
    ratio: 9 / 16,
    bg: "grey.800"
  }, "Visual"))));
};

export default EssayHeaderPattern;